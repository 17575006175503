import React, { useContext, useEffect, useState } from 'react';
import axios from 'axios';
import { LuImagePlus } from 'react-icons/lu';
import {
  Drawer,
  DrawerBody,
  DrawerOverlay,
  DrawerContent,
  DrawerCloseButton,
  useDisclosure,
  Button,
  Icon,
  Avatar,
  Divider,
} from '@chakra-ui/react';
import { Tabs, TabList, TabPanels, Tab, TabPanel } from '@chakra-ui/react';
import HorizontalNonLinearStepper from './HorizontalNonLinearStepper';
import StepperIndicator from './StepperIndicator';
import { VerificationContext } from '../../context/VerificationContext';
import { BsCheckCircle } from 'react-icons/bs';
import './DrawerComponent.scss';
import { BsArrowLeftCircle, BsBellFill } from 'react-icons/bs';
import { MdOutlineRadioButtonUnchecked } from 'react-icons/md';
import { AiOutlineFileDone } from 'react-icons/ai';
import { FiDownloadCloud } from 'react-icons/fi';
// import { AddBankCardFormComponent } from "./../FormsComponent/FormsComponent";

import { useParams } from 'react-router-dom';
import {
  AddMilestones,
  SubscriptionPayCard,
  ApplyForProjectTab,
  BidsTab,
  CreatePortfolioTab,
  DepositTab,
  DocumentsTab,
  DownloadReportTab,
  GalleryTab,
  LanguageSettingsTab,
  MilestoneTab,
  NotificationSettingsTab,
  NotificationTab,
  PasswordSecuritySettingsTab,
  PortfolioDetailesTab,
  PrivacyPolicySettingsTab,
  StatsSettingsTab,
  TransferTab,
  WithdrawTab,
  WriteProjectReport,
  Livestream,
  ProjectChatRoom,
  ImportProjects,
  CreateProcurementRequest,
  ViewClockIn,
  ViewUserPermission,
  CreateEvent,
  CreateAnnouncement,
  UserProjectsTabs,
  SiteInventory
} from './Tabs';
import { useCookies } from 'react-cookie';
import { Link } from 'react-router-dom';
import { AuthContext } from '../../context/AuthContext';
import { formatNumber } from '../../lib';

function DrawerComponent({ children }) {
  const { isOpen, onOpen, onClose } = useDisclosure();
  const firstField = React.useRef();

  const { activeStep, stepsContents } = React.useContext(VerificationContext);

  return (
    <>
      <div onClick={onOpen}>{children}</div>

      <Drawer
        isOpen={isOpen}
        placement="right"
        initialFocusRef={firstField}
        onClose={onClose}
        className="DrawerComponent"
      >
        <DrawerOverlay />
        <DrawerContent>
          <DrawerCloseButton />
          <div className="top-header">
            <h2 className='text-sm sm:text-lg font-semibold'>{stepsContents[activeStep].title}</h2>
            <p style={{ fontSize: 14 }}>
              {stepsContents[activeStep].descriptions}
            </p>
          </div>
          <div className="stepper-container">
            <HorizontalNonLinearStepper stepsContents={stepsContents} />
          </div>
          <DrawerBody>
            <div className="row mt-2 form-container mx-auto">
              {stepsContents[activeStep].content}
              <div className="col-12 mt-3 d-flex flex-column">
                <StepperIndicator />
              </div>
            </div>
          </DrawerBody>
        </DrawerContent>
      </Drawer>
    </>
  );
}

export default DrawerComponent;

export function MainDrawerComponent({
  children,
  open,
  card,
  projectDetailes,
  project,
  size,
  fetchRequests,
  active_subscription,
  selected,
  refresh,
  attendance,
  employee,
  is_addon,
  project_id
}) {
  const { isOpen, onOpen, onClose } = useDisclosure();
  const firstField = React.useRef();
  // const { activeStep, stepsContents } = React.useContext(VerificationContext);
  // // console.log("DrawerComponent", projectDetailes?.project_notifications);
  // // console.log("DrawerComponent", projectDetailes?.project_withdrawals);
  // // console.log("DrawerComponent", projectDetailes?.project_milestones);
  return (
    <div className="overflow-x-hidden">
      <div onClick={onOpen}>{children}</div>
      <Drawer onClose={onClose} isOpen={isOpen} size={size ? size : 'lg'}>
        <DrawerOverlay />
        <DrawerContent containerProps={{
          transform: 'translateX(0px) translateY(0px) translateZ(0px)'
        }} className="">
          <DrawerCloseButton />
          <div className="top-header">
            <h2 className="text-start mx-4 text-sm sm:text-lg font-bold">{card.sub}</h2>
          </div>
          <div className="text-start mx-4 lg:mx-10 overflow-auto custom-scrollbar">
            {open === 0 && (
              <MilestoneTab
                milestones={projectDetailes?.project_milestones}
                closeTabFunction={onClose}
              />
            )}
            {open === 2 && (
              <NotificationTab
                notifications={projectDetailes?.project_notifications}
              />
            )}
            {open === 3 && (
              <DocumentsTab documents={projectDetailes?.project_documents} />
            )}
            {open === 1 && (
              <TransferTab
                transfers={{
                  withdrawals: projectDetailes?.project_withdrawals,
                  credits: projectDetailes?.project_credits,
                }}
              />
            )}
            {open === 5 && <WithdrawTab closeTabFunction={onClose} />}
            {open === 6 && (
              <ApplyForProjectTab
                project={project}
                closeTabFunction={onClose}
              />
            )}
            {open === 7 && <CreatePortfolioTab />}
            {open === 8 && <PortfolioDetailesTab project={project} />}
            {open === 9 && <NotificationSettingsTab />}
            {open === 10 && <LanguageSettingsTab />}
            {open === 11 && <PasswordSecuritySettingsTab closeTabFunction={onClose} />}
            {open === 12 && <PrivacyPolicySettingsTab />}
            {open === 13 && <StatsSettingsTab />}
            {open === 14 && (
              <DepositTab
                projectDetailes={projectDetailes}
                closeTabFunction={onClose}
                project_id={project_id}
              />
            )}
            {open === 15 && <GalleryTab closeTabFunction={onClose} />}
            {open === 16 && (
              <DownloadReportTab
                reports={project?.project_milestones}
                closeTabFunction={onClose}
              />
            )}
            {open === 17 ? <BidsTab project={project} /> : null}
            {open === 18 ? (
              <AddMilestones
                closeTabFunction={onClose}
                project={project}
                project_milestones={project}
              />
            ) : null}
            {open === 19 ? (
              <SubscriptionPayCard
                closeTabFunction={onClose}
                details={projectDetailes}
                price={card.price}
                plan={card.plan}
                project={project}
                is_addon={is_addon}
                active_subscription = {active_subscription}
              />
            ) : null}
            {open === 25 && (
              <Livestream
                project={projectDetailes}
                closeTabFunction={onClose}
              />
            )}
            {open === 26 && (
              <WriteProjectReport
                project={projectDetailes}
                closeTabFunction={onClose}
              />
            )}
            {open === 27 && (
              <ProjectChatRoom
                project={projectDetailes}
                closeTabFunction={onClose}
              />
            )}
            {open === 28 && <ImportProjects closeTabFunction={onClose} />}
            {open === 29 && (
              <CreateProcurementRequest
                closeTabFunction={onClose}
                fetchRequests={fetchRequests}
              />
            )}
            {open === 30 && (
              <ViewClockIn attendance={attendance} closeTabFunction={onClose} selected={selected} />
            )}
            
            {open === 31 && (
              <CreateEvent refresh={refresh} closeTabFunction={onClose} />
            )}
            {open === 32 && (
              <CreateAnnouncement refresh={refresh} closeTabFunction={onClose} />
            )}
            {open === 33 && (
              <ViewUserPermission employee={employee} closeTabFunction={onClose} />
            )}
            {open === 34 && (
              <UserProjectsTabs employee={employee} closeTabFunction={onClose} />
            )}
            {open === 35 && (
              <SiteInventory project_id={project_id} closeTabFunction={onClose} />
            )}
          </div>
        </DrawerContent>
      </Drawer>
    </div>
  );
}

export function DocumentsCard(props) {
  const [cookies] = useCookies();
  const { userProfile } = React.useContext(AuthContext);

  function downloadPDF(uri, name) {
    const downloadLink = document.createElement('a');
    downloadLink.href = uri;
    downloadLink.download = name;
    downloadLink.target = '_blank'; // Open in a new tab/window

    // Trigger a click event on the link to initiate the download
    downloadLink.click();

    // Remove the link element after the download is initiated
    downloadLink.remove();
  }

  return (
    <div
      onClick={async () => {
        props.setViewDocument(props.document);
        // props.setViewingDocument(true);
        console.log('h', props.document) 
      }}
      className="p-3 cursor-pointer rounded-xl bg-zinc-100 transition-all ease-in-out my-3 flex items-start gap-4 pt-10"
    >
      <div className="flex-1">
        <div>
          <h5 className="text-[15px] font-bold">
            {props.document.document_name}
          </h5>
          {props.document.to_be_shared_by == userProfile?.sub_account_id ? (
            <h6 className="text-sm">
              You have been requested to share this document
            </h6>
          ) : null}
        </div>
        <div className="text-sm">{props.document.time}</div>
      </div>
      <div className="mt-2 flex gap-4 cursor-pointer">
        <button
          className={`font-bold text-[#000aff] bg-gray-200 py-2 px-4 rounded-full ${
            props.document.is_document_shared ? 'text-black' : 'text-[#000aff]'
          }`}
        >
          {props.document.is_document_shared ? 'Shared' : 'Share'}
        </button>
        {userProfile?.sub_account_overview?.allowed_permissions?.includes("sign_documents") || userProfile?.sub_account_overview?.allowed_permissions?.includes("full_access") && (
        <button
          className={`font-bold text-[#000aff] bg-gray-200 py-2 px-4  rounded-full ${
            props.document.is_document_signed ? 'text-black' : 'text-[#000aff]'
          }`}
        >
          {props.document.is_document_signed ? 'Signed' : 'Sign'}
        </button>
        )}
      </div>
    </div>
  );
}

export function SavedBankAccountCard() {
  return (
    <div className="bank-card-container d-flex justify-content-between p-3">
      <div className="d-flex gap-3">
        <Icon className="my-auto" />
        <div>
          {' '}
          <b>Fidelity Bank</b> <br />
          2345****21
        </div>
      </div>
      <div className="my-auto">remove</div>
    </div>
  );
}

export function TabPanelComponent({
  btn1Text,
  btn2Text,
  tab1Content,
  tab2Content,
}) {
  return (
    <Tabs>
      <TabList>
        <Tab className="tab-button">{btn1Text}</Tab>
        <Tab className="tab-button">{btn2Text}</Tab>
      </TabList>
      <TabPanels>
        <TabPanel>{tab1Content}</TabPanel>
        <TabPanel>{tab2Content}</TabPanel>
      </TabPanels>
    </Tabs>
  );
}

export function TransferCard({ icon, data, setSelected, index }) {
  return (
    <div className="TransferCard py-2" onClick={() => setSelected(data)}>
      <div className='hidden sm:block'>
        {/* <Icon /> */}
        <img src={icon} alt="" />
      </div>
      <div>
        <h2 className="transferCard-header">
          {data.account_name} - ₦{formatNumber(data.amount)}
        </h2>
        {/* <p>{data.description}</p> */}
      </div>
      <div className="flex-col">
        <b>Status</b>
        <span>{data.status}</span>
      </div>
      <div className="hidden sm:!block">
        <div className="time"> {data.time}</div>
      </div>
    </div>
  );
}
export function NotificationCard({ data }) {
  return (
    <div className="p-2 rounded-xl bg-gray-50 hover:bg-[#cec8e1] transition-all ease-in-out my-3 flex items-start gap-4">
      <div className="mt-2">
        {/* <img src={notification_ball} /> */}
        <Icon as={BsBellFill} w={6} h={6} />
      </div>
      <div className="w-full">
        <div>
          <h5 className="text-[15px] font-bold">{data.title}</h5>
          <p className="text-[13px] mb-0 text-gray-500">{data.message}</p>
        </div>
        <div className="text-[13px] text-end"> {data.time}</div>
      </div>
    </div>
  );
}
export function MilestoneCard({ data, setSelected }) {
  return (
    <div
      className={`flex px-3 py-3 items-center justify-between my-2 rounded-xl cursor-pointer ${
        data.milestone_started && !data.complete ? 'bg-blue-100' : ''
      } ${data.milestone_started && data.complete ? 'bg-emerald-100' : ''} ${
        !data.milestone_started &&
        !data.complete &&
        data.delayed_start == 'Expected to start today'
          ? 'bg-[#ffd561]'
          : ''
      } ${
        !data.milestone_started &&
        !data.complete &&
        data.delayed_start != '' &&
        data.delayed_start != 'Expected to start today'
          ? 'bg-[#f79c9c]'
          : ''
      } ${
        !data.milestone_started && !data.complete && data.delayed_start == ''
          ? 'bg-gray-100'
          : ''
      }`}
      onClick={() => setSelected(data)}
    >
      <div className="flex flex-column items-start">
        {/* <Icon /> */}
        {/* {data?.complete ? (
          <BsCheckCircle height={20} width={20} />
        ) : (
          <MdOutlineRadioButtonUnchecked height={20} width={20} />
        )} */}
        <div className="font-bold text-[13px]">
          {data.milestone +
            ' (' +
            data.milestone_metric_target_covered +
            ' of ' +
            data.milestone_metric_target +
            ' ' +
            data.milestone_metric_system +
            ')'}
        </div>
        <div className="text-xs">
          <a className="font-bold text-[11px]">Duration: </a> {data.duration}
        </div>
        {data.milestone_started && (
          <div className="text-xs">
            <a className="font-bold text-[11px]">Started: </a>{' '}
            {data.duration_elapsed}
          </div>
        )}
        {data.delayed_start != '' && (
          <div className="text-xs font-bold text-[11px]">
            {data.delayed_start}
          </div>
        )}
        {/* {data.milestone_metric_target != "" && (<div className="text-xs">{data.milestone_metric_target_covered + ' of ' + data.milestone_metric_target + ' ' + data.milestone_metric_system}</div>)} */}
      </div>
      <div className="">
        <div className="date text-xs">
          {'End date: ' + data.milestone_end_date}
        </div>
      </div>
    </div>
  );
}
export function BidProfileCard({ bid, setBidId }) {
  return (
    <div className="BidProfileCard">
      <div className="d-flex gap-3 align-items-center">
        <Avatar src={bid.contractor_display_picture} />
        <div>
          <h2>{bid?.contractor_name || 'Profile Name'}</h2>
          <p>{bid.time}</p>
        </div>
      </div>
      <div className="">
        <Divider />
        <div className="d-flex justify-content-between">
          <button
            className="bg-native-blue text-white"
            onClick={() => setBidId(bid?.bid_id)}
          >
            View Profile
          </button>
        </div>
      </div>
    </div>
  );
}
